import Vue from 'vue'
const DEV_API='http://localhost:8000/api/v1';
const PROD_API='https://areav2.imagixel.com/api/api/v1';


const MAPS_API_KEY='AIzaSyDB1_Z9uemxsuIjjWVaG64hLEidiB2RR2g';


const apiUrl=process.env.NODE_ENV === 'development'?DEV_API:PROD_API;
Vue.prototype.$apiUrl = apiUrl;

import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from '@mathieustan/vue-notification';
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import 'leaflet/dist/leaflet.css';


import {numberFormat} from '@/util/input-directives.js';

import Multiselect from 'vue-multiselect'

Vue.component('multiselect', Multiselect)

Vue.directive('number-format', numberFormat)


Vue.component('Datepicker', VueDatepickerUi)





import { SkeletonPlugin } from 'bootstrap-vue'
Vue.use(SkeletonPlugin)

import { Vue2Dragula } from 'vue2-dragula'

Vue.use(Vue2Dragula, {
  logging: {
    service: true // to only log methods in service (DragulaService)
  }
});

Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)

Vue.use(VueNotification, {
  
  theme: {
    colors: {
      success: '#7edbca',
      darkenSuccess: '#2d8e36',
      info: '#5d6a89',
      darkenInfo: '#535f7b',
      warning: '#f8a623',
      darkenWarning: '#f69a07',
      error: '#ff4577',
      darkenError: '#ff245f',
      offline: '#ff4577',
      darkenOffline: '#ff245f',
    },
    boxShadow: '4px 4px 4px 1px #ccc',
  },
  
  breakpoints: {
    0: {
      top: true,
    },
    480: {
      top: true,
      right: true,
    },
  },
});


import * as VueGoogleMaps from 'vue2-google-maps'
 
Vue.use(VueGoogleMaps, {
  load: {
    key: MAPS_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});


Vue.prototype.$thumb=function(src){
  if(src){
    const src_sm=src.replace('.','-sm.');
    return this.$apiUrl+'/../..'+src_sm;
  }
}
Vue.prototype.$pic=function(src){
  if(src){
    return this.$apiUrl+'/../..'+src;
  }
}


Vue.prototype.$dateToString=function(date){


  if(typeof(date)!='string'){

    
    const rday = date.getDate();
    let rmonth = date.getMonth()+1;
    const ryear = date.getFullYear();
    
    return ryear+'-'+rmonth+'-'+rday;
  }

  return date;
}

Vue.prototype.$stringToDate=function(date){

  const [year, month, day] = date.split('-');
  
  return new Date(`${year}-${month}/${day}`);

}

Vue.prototype.$addMonths = function(date, months) {
  const [year, month, day] = date.split('-');
  
  const objDate = new Date(`${month}/${day}/${year}`);
  objDate.setMonth(objDate.getMonth() + months);

  const rday = objDate.getDate();
  const ryear = objDate.getFullYear();
  const rmonth = objDate.getMonth() + 1;  // Sumar 1 para obtener el mes correcto

  return ryear + '-' + rmonth + '-' + rday;
};



Vue.prototype.$customDate=function(date,fullname){



  const [year, month, day] = date.split('-');
  const objDate = new Date(`${month}/${day}/${year}`);
  const rday = objDate.getDate();
  const ryear = objDate.getFullYear();
  let rmonth = objDate.toLocaleString('es-ES', { month: 'long' });

  if(!fullname){
    rmonth=rmonth.substring(0,3);
  }
  

  return rday+' '+rmonth+' '+ryear;

}


Vue.prototype.$dateFormat=function(date,fullname){


  if(date!=undefined){

    date=date.split(' ')[0];

    
    const [year, month, day] = date.split('-');
    const objDate = new Date(`${month}/${day}/${year}`);
    const rday = objDate.getDate();
    const ryear = objDate.getFullYear();
    let rmonth = objDate.toLocaleString('es-ES', { month: 'long' });
    
    if(!fullname){
      rmonth=rmonth.substring(0,3);
    }
    
    
    return rday+' '+rmonth+' '+ryear;
  }

  return date;

}



Vue.prototype.$numberFormat = function(number) {
  if (typeof number !== 'string') {
    number = number.toString();
  }

  let floatValue = parseFloat(number.replace(/[^0-9.]/g, ''));
  let formattedNumber;

  if (Number.isInteger(floatValue) || floatValue % 1 === 0) {
    formattedNumber = floatValue.toLocaleString('es-MX');
  } else {
    formattedNumber = floatValue.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  return formattedNumber;
};





Vue.prototype.$float=function(n){

  let l=n;
  if(typeof(n)=='string'){
    l=n.replaceAll(',','');
  }
  let v=parseFloat(l);

  return v;
}

Vue.prototype.$int=function(n){

  let l=n;
  if(typeof(n)=='string'){
    l=n.replaceAll(',','');
  }
  let v=parseInt(l);

  return v;
}



Vue.prototype.google = VueGoogleMaps.gmapApi;

Vue.config.productionTip = false


const bootstrapMixin = {

    computed:{



          isNewRecord(){

            return this.$props.id?false:true;

          },


          createFormData(){

          return function(model,ignore){

            ignore=ignore?ignore:[];

            const data = new FormData();

            for (const key in model) {

                if(ignore.indexOf(key)!==-1){
                  continue;
                }

                let item=model[key];
                if (item!=undefined) {
                    if(typeof item=='object' && !(item instanceof File)){
                      item=JSON.stringify(item);
                    }
                    data.append(key, item);
                }
            }

            return data;
          }


        },

        fillModel(){

          return function(model,data){

            for (const key in data) {
              if (model.hasOwnProperty(key) && data[key]) {

                  model[key] = data[key];
              }

              
            }
          }

        },

        thumb(){

          return function(src){
            return this.$apiUrl+'/../..'+src.replace('.','-sm.');
          }

        },

        fileico(){

          return function(n){

            
            if(n.indexOf('.doc')!=-1){

              return 'mdi mdi-file-word-outline';
            }
            else if(n.indexOf('.pdf')!=-1){

              
              return 'mdi mdi-file-pdf-box';
            }
            else if(n!=''){
              return 'mdi mdi-file-outline';
            }else{
              return '';
            }

          }
        },

        filename(){

          return function(n){ 
              
              const extName = n.substring(n.lastIndexOf('/') + 1);
              const hasAlpha = /[a-zA-Z]/.test(extName);


              if (hasAlpha) {
                const nClean = extName.replace(/^\d+/, '');
                return nClean;
              } else {
                return extName;
              }

          }

        },

        fil(){

          return function(src){
            return this.$apiUrl+'/../..'+src;
          }


        },

        alias(){

          
          if(this.$options.routeName){

            return function(){
              return this.$router.resolve({ name: this.$options.routeName }).route.matched[0].path;
            }
            
          }
          
        },
        link(){
          
          if(this.$options.routeName){
            return function(to){
              return this.alias()+to;
            }
          }
        },
        
        me(){
          
          if(this.$options.routeName){
            return function(url_request){
                  
              
                  let the_route=this.$router.resolve({ name: this.$options.routeName }).route.matched[0].alias.toString();
              

                  let start_url=the_route,end_url='',end_params_separator='?';

                  //si la variable the_route correspondiente contiene parámetros url
                  
                  if(the_route.indexOf('?')!=-1){
                      //extraerlos y agregarlos hasta el final de la url
                      //pero ahora con el separador &
                    const route_pieces=the_route.split('?');
                    start_url=route_pieces[0];
                    end_url=route_pieces[1];
                    
                  }



                  /*********Validación para quitar slash del final de urls, para evitar usar redirecciones de htaccess
                   * en el backend
                   */
                  
                  
                  
                  //si la url que pide el usuario no es vacía
                  if(url_request){
                      url_request=url_request.toString();

                      if(url_request.length>0){
                    
                        
                        //Si la url que solicita el usuario no comienza con separador de directorio (/), agregarlo
                        //con esto se puede usar por ejemplo: this.me('/amenities') o this.me('amenities') indistintamente
                        //
                        if(url_request.charAt(0)!='/'){
                            start_url+='/';
                        }else if(url_request.length==1){
                            url_request='';
                        }

                        //cambiar separador de url params del url del router
                        if(url_request.indexOf('?')!=-1){
                          end_params_separator='&';
                        }
                  

                        
                        start_url+=url_request;

                        //Reemplazar /? por ?
                        start_url=start_url.replace('/?','?');



                      }



                  }




                  if(end_url){
                    start_url+=end_params_separator+end_url;
                  }

                  return start_url;
            
            }
            
          }

    }


  },


    methods:{

      /****(c)2018 gustavoSoriano/willvalidate library*/
      /*****Modificado para validar campos cuando estén deshabilitados */
      clearFormErrors(form){
        
        Array.from( form.querySelectorAll('.has-danger') ).map( el => el.classList.remove('has-danger') )
        Array.from( form.querySelectorAll('small') ).map( el => el.remove() )

      },
      validateForm(form){

              this.clearFormErrors(form);
              let firstError=null
            
              Array.from( form ).map( field => {

                  let reenable=false;
                  if(field.disabled){
                    field.removeAttribute('disabled');
                    reenable=true;
                  }

                  if (field.willValidate && !field.checkValidity()) 
                  {
                    if (!firstError) firstError = field
                      this.addInputError(field);
                  } 


                  if(reenable){
                    field.setAttribute('disabled','disabled');
                  }

              })
          
              if (firstError) firstError.focus()
              return firstError === null
  
    

      },
      setFormError(form,input_name,message){

        Array.from( form ).map( field => {
            if(field.name==input_name){
              this.addInputError(field,message);
              return;
            }
            
        });
        
      },

      addInputError(field,message){

        const small= document.createElement('small')

        if(message)
          small.innerHTML=message;
        else if(field.getAttribute("errorMessage"))
          small.innerHTML = field.getAttribute("errorMessage")
        else 
          small.innerHTML = field.validationMessage
          
        small.className = 'form-control-feedback'
        field.parentElement.appendChild(small)
        field.parentElement.classList.add('has-danger')


      }


    }



}
Vue.mixin(bootstrapMixin)


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

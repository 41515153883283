import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'


Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      component: layout,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/pages/dashboard')
        }
      ]
    },
    {
      name:'amenities',
      path: '/amenities',
      alias:'/amenities',
      component: layout,
      children: [
        {
          path: '',
          name: 'listAmenities',
          component: () => import('@/pages/amenities')
        },
        {
          path: 'create',
          name: 'createAmenity',
          component: () => import('@/pages/amenities/create')
        },
        {
          path: 'update/:id',
          name: 'updateAmenity',
          component: () => import('@/pages/amenities/update'),
          props:true
        }
      ]

    },


    {
      name:'developments',
      path: '/developments',
      alias:'/developments',
      component: layout,
      children: [
        {
          path: '',
          name: 'listDevelopments',
          component: () => import('@/pages/developments')
        },
        {
          path: 'create',
          name: 'createDevelopment',
          component: () => import('@/pages/developments/create')
        },
        {
          path: 'update/:id',
          name: 'updateDevelopment',
          component: () => import('@/pages/developments/update'),
          props:true
        },
        {
          path: 'view/:id',
          name: 'viewDevelopment',
          component: () => import('@/pages/developments/view'),
          props:true
        }
      ]

    },    


    {
      name:'designs',
      path: '/designs',
      alias:'/designs',
      component: layout,
      children: [
        {
          path: '',
          name: 'listDesigns',
          component: () => import('@/pages/designs')
        },
        {
          path: 'create',
          name: 'createDesign',
          component: () => import('@/pages/designs/create')
        },
        {
          path: 'update/:id',
          name: 'updateDesign',
          component: () => import('@/pages/designs/update'),
          props:true
        }
      ]

    },        

    {
      name:'equipment',
      path: '/equipment',
      alias:'/equipment',
      component: layout,
      children: [
        {
          path: '',
          name: 'listEquipments',
          component: () => import('@/pages/equipment')
        },
        {
          path: 'create',
          name: 'createEquipment',
          component: () => import('@/pages/equipment/create')
        },
        {
          path: 'update/:id',
          name: 'updateEquipment',
          component: () => import('@/pages/equipment/update'),
          props:true
        }
      ]

    },        
    {
      name:'properties',
      path: '/properties',
      alias:'/properties',
      component: layout,
      children: [
        {
          path: '',
          name: 'listProperties',
          component: () => import('@/pages/properties')
        },
        {
          path: 'create',
          name: 'createProperties',
          component: () => import('@/pages/properties/create')
        },
        {
          path: 'update/:id',
          name: 'updateProperties',
          component: () => import('@/pages/properties/update'),
          props:true
        }
      ]

    },        
    {
      name:'developmentgalleries',
      path: '/developmentgalleries',
      alias:'/developmentgalleries',
      component: layout,
      children: [
        {
          path: '',
          name: 'listDevGalleries',
          component: () => import('@/pages/developmentgalleries')
        },
        {
          path: 'create',
          name: 'createDevGallery',
          component: () => import('@/pages/developmentgalleries/create')
        },
        {
          path: 'update/:id',
          name: 'updateDevGallery',
          component: () => import('@/pages/developmentgalleries/update'),
          props:true
        }
      ]

    },        

    {
      name:'clients',
      path: '/clients',
      alias:'/clients',
      component: layout,
      children: [
        {
          path: '',
          name: 'listClients',
          component: () => import('@/pages/clients')
        },
        {
          path: 'create',
          name: 'createClient',
          component: () => import('@/pages/clients/create')
        },
        {
          path: 'update/:id',
          name: 'updateClient',
          component: () => import('@/pages/clients/update'),
          props:true
        }
      ]

    },        

    {
      name:'leads',
      path: '/leads',
      alias:'/clients?type=lead',
      component: layout,
      children: [
        {
          path: '',
          name: 'listLeads',
          component: () => import('@/pages/leads'),
        },
        {
          path: 'create',
          name: 'createLead',
          component: () => import('@/pages/leads/create')
        },
        {
          path: 'update/:id',
          name: 'updateLead',
          component: () => import('@/pages/leads/update'),
          props:true
        }
      ]

    },     


    {
      name:'funds',
      path: '/funds',
      alias:'/funds',
      component: layout,
      children: [
        {
          path: '',
          name: 'listFunds',
          component: () => import('@/pages/funds'),
        },
        {
          path: 'create',
          name: 'createFund',
          component: () => import('@/pages/funds/create')
        },
        {
          path: 'update/:id',
          name: 'updateFund',
          component: () => import('@/pages/funds/update'),
          props:true
        }
      ]

    },     


    {
      name:'budgets',
      path: '/budgets',
      alias:'/budgets',
      component: layout,
      children: [
        {
          path: '',
          name: 'listBudgets',
          component: () => import('@/pages/budgets'),
        },
        {
          path: 'create',
          name: 'createBudget',
          component: () => import('@/pages/budgets/create')
        },
        {
          path: 'update/:id',
          name: 'updateBudget',
          component: () => import('@/pages/budgets/update'),
          props:true
        }
      ]

    },     

    {
      name:'expenses',
      path: '/expenses',
      alias:'/expenses',
      component: layout,
      children: [
        {
          path: '',
          name: 'listExpenses',
          component: () => import('@/pages/expenses'),
        },
        {
          path: 'create',
          name: 'createExpense',
          component: () => import('@/pages/expenses/create')
        },
        {
          path: 'update/:id',
          name: 'updateExpense',
          component: () => import('@/pages/expenses/update'),
          props:true
        }
      ]

    },     

    {
      name:'vendors',
      path: '/vendors',
      alias:'/vendors',
      component: layout,
      children: [
        {
          path: '',
          name: 'listVendors',
          component: () => import('@/pages/vendors'),
        },
        {
          path: 'create',
          name: 'createVendor',
          component: () => import('@/pages/vendors/create')
        },
        {
          path: 'update/:id',
          name: 'updateVendor',
          component: () => import('@/pages/vendors/update'),
          props:true
        }
      ]

    },     
    {
      name:'profiles',
      path: '/profiles',
      alias:'/profiles',
      component: layout,
      children: [
        {
          path: '',
          name: 'listProfiles',
          component: () => import('@/pages/profiles'),
        },
        {
          path: 'create',
          name: 'createProfile',
          component: () => import('@/pages/profiles/create')
        },
        {
          path: 'update/:id',
          name: 'updateProfile',
          component: () => import('@/pages/profiles/update'),
          props:true
        }
      ]

    },
    {
      name:'sales',
      path: '/sales',
      alias:'/sales',
      component: layout,
      children: [
        {
          path: '',
          name: 'listSales',
          component: () => import('@/pages/sales'),
        },
        {
          path: 'create',
          name: 'createSale',
          component: () => import('@/pages/sales/create')
        },
        {
          path: 'update/:id',
          name: 'updateSale',
          component: () => import('@/pages/sales/update'),
          props:true
        }
      ]

    },     


    {
      name:'credits',
      path: '/credits',
      alias:'/sales',
      component: layout,
      children: [
        {
          path: '',
          name: 'listCredits',
          component: () => import('@/pages/credits'),
        },
      ]

    },     


    {
      name:'payments',
      path: '/payments',
      alias:'/payments',
      component: layout,
      children: [
        {
          path: '',
          name: 'listPayments',
          component: () => import('@/pages/payments'),
        },

        /*
        {
          path: 'create',
          name: 'createPayment',
          component: () => import('@/pages/payments/create')
        },
        {
          path: 'update/:id',
          name: 'updatePayment',
          component: () => import('@/pages/payments/update'),
          props:true
        }*/
      ]

    },     


    {
      name:'documents',
      path: '/documents',
      alias:'/documents',
      component: layout,
      children: [
        {
          path: '',
          name: 'listDocuments',
          component: () => import('@/pages/documents'),
        },
        {
          path: 'create',
          name: 'createSale',
          component: () => import('@/pages/documents/create')
        },
        {
          path: 'update/:id',
          name: 'updateSale',
          component: () => import('@/pages/documents/update'),
          props:true
        }
      ]

    },
    {
      name:'phases',
      path: '/phases',
      alias:'/phases',
      component: layout,
      children: [
        {
          path: '',
          name: 'listPhases',
          component: () => import('@/pages/phases'),
        },
        {
          path: 'create',
          name: 'createSale',
          component: () => import('@/pages/phases/create')
        },
        {
          path: 'update/:id',
          name: 'updateSale',
          component: () => import('@/pages/phases/update'),
          props:true
        }
      ]

    },     

    {
      name:'processes',
      path: '/processes',
      alias:'/processes',
      component: layout,
      children: [
        {
          path: '',
          name: 'listProcesses',
          component: () => import('@/pages/processes'),
        },
        {
          path: 'create',
          name: 'createProcess',
          component: () => import('@/pages/processes/create')
        },
        {
          path: 'update/:id',
          name: 'updateProcess',
          component: () => import('@/pages/processes/update'),
          props:true
        }
      ]

    },
    {
      name:'currencies',
      path: '/currencies',
      alias:'/currencies',
      component: layout,
      children: [
        {
          path: '',
          name: 'listCurrencies',
          component: () => import('@/pages/currencies'),
        },
        {
          path: 'create',
          name: 'createCurrency',
          component: () => import('@/pages/currencies/create')
        },
        {
          path: 'update/:id',
          name: 'updateCurrency',
          component: () => import('@/pages/currencies/update'),
          props:true
        }
      ]

    },

    {
      name:'quotations',
      path: '/quotations',
      alias:'/quotations',
      component: layout,
      children: [
        {
          path: '',
          name: 'listQuotations',
          component: () => import('@/pages/quotations'),
        },
        {
          path: 'create',
          name: 'createQuotation',
          component: () => import('@/pages/quotations/create')
        },
        {
          path: 'developments',
          name: 'createSelectDevelopment',
          component: () => import('@/pages/quotations/developments')
        },
        {
          path: 'map/:id',
          name: 'selectMap',
          component: () => import('@/pages/quotations/map/')
        },
        {
          path: 'update/:id',
          name: 'updateQuotation',
          component: () => import('@/pages/quotations/update'),
          props:true
        }
      ]

    },     




    {
      path: '/basic-ui',
      component: layout,
      children: [
        {
          path: 'buttons',
          name: 'buttons',
          component: () => import('@/pages/basic-ui/buttons')
        },
        {
          path: 'dropdowns',
          name: 'dropdowns',
          component: () => import('@/pages/basic-ui/dropdowns')
        },
        {
          path: 'typography',
          name: 'typography',
          component: () => import('@/pages/basic-ui/typography')
        }
      ]
    },
    {
      path: '/charts',
      component: layout,
      children: [
        {
          path: 'chartjs',
          name: 'chartjs',
          component: () => import('@/pages/charts/chartjs')
        },
      ]
    },
    {
      path: '/tables',
      component: layout,
      children: [
        {
          path: 'basic-tables',
          name: 'basic-tables',
          component: () => import('@/pages/tables/basic-tables')
        }
      ]
    },
    /*
    {
      path: '/forms',
      component: layout,
      children: [
        {
          path: 'basic-tables',
          name: 'basic-tables',
          component: () => import('@/pages/forms/basic-tables')
        }
      ]
    },*/
    {
      path: '/user',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/samples/auth-pages/login')
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/pages/samples/auth-pages/register')
        }
      ]
    },
    {
      path: '/error-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          name: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        },
        {
          path: 'error-500',
          name: 'error-500',
          component: () => import('@/pages/samples/error-pages/error-500')
        }
      ]
    },
    {
      path: '/icons',
      component: layout,
      children: [
        {
          path: 'mdi-icons',
          name: 'mdi-icons',
          component: () => import('@/pages/icons/mdi-icons')
        }
      ]
    },
    {
      path: '*',
      redirect: '/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        }
      ]
    }
  ]
})
